
import Vue from "vue";

export default Vue.extend({
  name: "Notifications",

  data: () => ({
    notificationCount: 0 as number,
    notifications: [] as Array<any>,
    loading: false as boolean,
    intervalId: null as any,
    page: 1 as number,
    pagination: {
      number: 1 as number,
      size: 7 as number
    } as any
  }),

  computed: {
    user() {
      return this.$store.getters["authentication/credentials"].user;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isSpecialist() {
      return this.currentRole.alias === "specialist";
    }
  },

  async mounted() {
    await this.getNotificationsCount();
    const getNotifications = () => this.getNotificationsCount();
    this.intervalId = setInterval(getNotifications, 60000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    async getNotificationsCount(): Promise<void> {
      try {
        if (this.$route.name !== "login") {
          this.notificationCount = await this.$API.notifications().getCount();
        } else {
          clearInterval(this.intervalId);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    async readNotification(item: any): Promise<void> {
      try {
        if (item && !item.read_at) {
          await this.$API.notifications().setRead(item.id);
          await this.getNotificationsCount();
          await this.getNotifications();
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getNotifications(event: any = {}): Promise<void> {
      this.loading = true;
      try {
        if (event) {
          this.notifications = [];
          await this.getNotificationsCount();
          const response = await this.$API.notifications().getList({
            "page[number]": this.pagination.number,
            "page[size]": this.pagination.size
          });
          this.notifications = response.items.data;
          this.pagination.number = response.items.current_page;
          this.pagination.total = Math.ceil(
            response.items.total / this.pagination.size
          );
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    getUserAvatar() {
      return this.user.avatar.url;
    },
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        await this.$router.push("/auth/login");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    parseUsername() {
      return this.user?.name
        .split(" ")
        .map(([item]: any) => item)
        .slice(0, 2)
        .join("")
        .toUpperCase();
    },
    async openOrder(item: any): Promise<void> {
      try {
        await this.readNotification(item);

        if (item.data.meta.order_id) {
          await this.$router.push(`/orders/create`);
          await this.$router.push(
            `/orders/edit/${item.data.meta.order_id}?additions=notes`
          );
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
