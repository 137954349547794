
import Vue from "vue";
import API from "@/api/API";

export default Vue.extend({
  name: "SideBar",

  data: () => ({
    logoImage: require("@/assets/images/logos/logo.png"),
    logoText: require("@/assets/images/logos/logo_text.svg"),
    items: [] as Array<MenuItemsInterface>,
    queryString: "" as string,
    currentRole: null as any
  }),

  computed: {
    filteredItems() {
      return this.items.map(item => ({
        ...item,
        items: item.items
          .filter(
            (item: any) =>
              item.children?.some(
                (el: any) =>
                  this.includesText(el.text) ||
                  el?.children?.some((child: any) =>
                    this.includesText(child.text)
                  )
              ) || this.includesText(item.text)
          )
          .map((item: any) => {
            if (item.children) {
              return {
                ...item,
                children: item.children.filter(
                  (child: any) =>
                    this.includesText(child.text) ||
                    child?.children?.some((el: any) =>
                      this.includesText(el.text)
                    )
                )
              };
            }
            return item;
          })
      }));
    },
    expanded: {
      get(): boolean {
        return this.$store.getters["navigation/expanded"];
      },
      set(value: boolean): void {
        this.$store.dispatch("navigation/set", value);
      }
    },
    user() {
      return this.$store.getters["user/info"];
    },
    credentials() {
      return this.$store.getters["authentication/credentials"];
    }
  },

  watch: {
    expanded(value) {
      if (value) {
        this.setSearchFocus();
      }
    },
    currentRole() {
      if (this.currentRole) {
        this.$store.dispatch("user/setRole", this.currentRole);
      }
    }
  },

  async beforeMount(): Promise<void> {
    if (!this.user) {
      await this.getUser();
    }
    await this.getCurrentRole();
    await this.loadMenu();
  },

  methods: {
    async getUser() {
      try {
        if (!this.credentials?.user) {
          return;
        }

        const {
          user: { id }
        } = this.credentials;
        const response = await API.users().checkStatus(id);
        await this.$store.dispatch("user/set", response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadMenu(): Promise<void> {
      if (!this.currentRole?.alias) {
        return;
      }

      const response: any = await import(`../../static/data/menu.json`);

      this.items =
        (response.default[this.currentRole.alias] as Array<
          MenuItemsInterface
        >) || [];
    },
    async getCurrentRole() {
      let role = localStorage.getItem("localRole") as any;

      if (role === "undefined") {
        role = null;
      }

      if (
        role &&
        this.user?.roles?.some(
          (item: any) => item.alias === JSON.parse(role)?.alias
        )
      ) {
        const roleIndex = this.user.roles.findIndex(
          (item: any) => item.alias === JSON.parse(role)?.alias
        );

        this.currentRole = this.user.roles[roleIndex];
      } else if (this.user?.roles?.length) {
        this.currentRole = this.user.roles[0];
      }
    },
    includesText(text: any): boolean {
      return this.$t(text)
        .toString()
        .toLowerCase()
        .includes(this.queryString.toLowerCase());
    },
    setSearchFocus() {
      setTimeout(() => {
        (this.$refs.searchInput as Vue)?.focus();
      }, 300);
    },
    async logout(): Promise<void> {
      try {
        await this.$store.dispatch("authentication/logout");
        await this.$router.push("/auth/login");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    close(value: boolean): void {
      if (!value) {
        this.$store.dispatch("navigation/set", value);
      }
    },
    toMain(): void {
      this.$router.push("/");
    }
  }
});
